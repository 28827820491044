import React, { useState } from 'react';
import { Card, Col, Row, Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ContactModal from './Modal'
import animalImgVertical from "../img/BuildAnimals Poster.jpg"
import Contact from '../pages/Contact';
import ReactPlayer from 'react-player'



const HorizontalCard = ({ img }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className="container p-0" >

            <Card className="m-4" onClick={e => console.log(window.innerWidth)}>
                <Row noGutters className="bg-dark rounded shadow animal-card">
                    <Col>
                        <Card.Img src={animalImgVertical} alt="Build Animals" />
                    </Col>
                    <Col className="d-flex flex-column align-items-center">
                        <Card.Body className="rounded center-body d-flex flex-column">

                            <Card.Title className="text-center text-light letter-spacing mb-5 title-font">
                                BUILD ANIMALS
                        </Card.Title>
                            <Card.Text className="mt-4">
                                “Build Away” is what the BuildAnimals always say.
                        </Card.Text>
                            <Card.Text className="mt-4">
                                Together with best pals Lilly and Zeke, this wild crew of construction savvy animals is always ready to dig, haul and build together.
                        </Card.Text>
                            <Card.Text className="mt-4">
                                From an aquarium in the sky to a city-sized snow globe on a tropical beach, there’s no project too imaginative for BuildAnimals to handle.
                        </Card.Text>
                            <Row className="bg-light  mt-5" style={{ height: "1.5px", margin: "0 20%" }}></Row>
                            <Card.Text className="mt-auto mb-5">
                                Please contact us for access to the video, and more information.
                        </Card.Text>
                            <div className="container-fluid d-flex justify-content-center flex-column align-items-center">

                                <Button className="btn-light contact-link" onClick={handleShow} onClose={handleClose}>
                                    <i class="fas fa-video mr-2"></i>
                                    Watch the Trailer Now!
                                </Button>
                                <Button className="btn-light mt-3" id="contact-button" onClick={handleShow}>
                                    <i class="far fa-envelope mr-2">
                                        <Link to="/contact" className="ml-2 contact-link">
                                            Contact Us!
                                        </Link>
                                    </i>

                                </Button>
                            </div>
                            <Modal show={show} onHide={handleClose} className="video-container modal-viewport">
                                <Modal.Body className="p-0">
                                    <div className="player-wrapper">
                                            <ReactPlayer
                                                className="react-player embed-responsive"
                                                url="https://vimeo.com/512556976?autoplay=1"
                                                controls
                                                width={window.innerWidth < 640 ? "100%" : "640px" }
                                            />

                                    </div>

                                </Modal.Body>
                            </Modal>

                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default HorizontalCard;